<template>
	<div class="wrapper_main footer-fixed">
		<main class="content">
			<div class="headline">
				<h1 class="headline__title">{{ $translate('add-a-group') }}</h1>
				<p class="headline__text">
					{{ $translate('set-up-a-rule-for-each-of-the-onboarded-iot-devices.') }}
				</p>
			</div>
			<div class="tabs-wrapper">
				<ul class="tabs">
					<li class="tabs__item" >
						<a data-tab="info" class="nav-tab"  @click="switchTab('info')" :class="isActiveTab('info')">
							{{ $translate('group-name') }}
						</a>
					</li>
					<li class="tabs__item" >
						<a data-tab="devices" class="nav-tab" @click="switchTab('devices')" :class="isActiveTab('devices')">
							{{ $translate('select-devices') }}
						</a>
					</li>
				</ul>
			</div>
			<div class="tab-content">
				<div id="info" class="tab-content__item" :class="isActiveTab('info')">
					<div class="addition">
						<div class="wizard-item">
							<div class="items-group cards">
								<div class="cards__col-6">
									<div class="el-form">
										<label class="el-form__title">{{ $translate('group-name') }}</label>
										<input type="text" class="input" v-model="name"/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="devices" class="tab-content__item" :class="isActiveTab('devices')">
					<div class="addition">
						<div class="headline headline--sm">
							<h2 class="headline__title">{{ $translate('selected-devices') }}</h2>
							<p class="headline__text">
								{{ $translate('to-set-up-rules-you-need-to-select-some-of-onboarded-iot-devices.') }}
							</p>
						</div>
						<DeviceRows :devices="fieldValues('devices')" @delete="deleteFieldItem"></DeviceRows>
						<!-- <ul class="editable-list">
							<li class="editable-list__item">
								<div class="data-row">
									<ul class="data-row__list">
										<li class="data-row__item">
											Thermometer Name 1
										</li>
										<li class="data-row__item">
											<span class="data-row__icon">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
													<path d="M12 8V14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
													<path d="M13.4142 14.5858C14.1953 15.3668 14.1953 16.6332 13.4142 17.4142C12.6332 18.1953 11.3668 18.1953 10.5858 17.4142C9.80474 16.6332 9.80474 15.3668 10.5858 14.5858C11.3668 13.8047 12.6332 13.8047 13.4142 14.5858" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
													<path fill-rule="evenodd" clip-rule="evenodd" d="M9 12.023V6C9 4.343 10.343 3 12 3C13.657 3 15 4.343 15 6V12.023C16.208 12.936 17 14.37 17 16C17 18.761 14.761 21 12 21C9.239 21 7 18.761 7 16C7 14.37 7.792 12.935 9 12.023Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
												</svg>
											</span>
											Temperature
										</li>
										<li class="data-row__item">
											Italy, Rome
										</li>
									</ul>
									<div class="data-row__controls">
										<button type="button" class="btn btn-icon red-color">
											<inline-svg :src="require('@/assets/img/Delete-circle.svg')" />
										</button>
									</div>
								</div>
							</li>
						</ul> -->
						<WhitePlusButton @click="addDeviceOpen">{{ $translate('add-iot-device') }}</WhitePlusButton>
					</div>
				</div>
			</div>
		</main>
		<FooterTabsControl
            previous=1
            cancelRoute="/device-groups"
            :confirmName="$translate('add-a-group')"
            @confirm="addGroup"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
			:processing="processing"
        />
		<!-- before translate confirmName="Add a Group" -->

		<AddDevice
			v-if="addDevicesPopupInfo.show"
			:devices="addDevicesPopupInfo.devices"
			@close="addDeviceClose"
			@submit="addDeviceSubmit"
			:title="$translate('assign-device-to-group')"
		/>
		<!-- before translate title="Assign Device(s) to group" -->
	</div>
</template>

<script>
import { mixTabs,mixProcessing,mixArrayFields } from "@/mixins";
import {
	createDeviceGroup,
	updateDevice
	
} from '@/graphql/mutations';
import { API } from 'aws-amplify';
import Base from "@/components/base";
import Popups from "@/components/popups";
import Buttons from "@/components/buttons";
import ItemsRows from "@/components/itemsRows";
export default {
	name: "AddDeviceGroup",
	components: {
		...Base,
		...Popups,
		...Buttons,
		...ItemsRows
	},
	mixins:[mixTabs,mixProcessing,mixArrayFields],

	data() {
		return {
			pageTabs:['info', 'devices'],
			name:'',

			arrayFields:{
				devices:[]
			},
												
		}
	},
	computed: {
		devices() {
			return this.$store.state.devices
		},
		companyData(){
			return this.$store.state.companyData
		}
	},
	methods: {
		async addGroup(){
			console.log("addGroup");
			this.processing=true
			console.log(this.fieldArray('devices'));
			
			
			this.processing=true
			
			let payload = {
				name: this.name,
				companyID: this.companyData.id,
			}
			const group = await API.graphql({
				query: createDeviceGroup,
				variables: {
					input: payload
				}
			}).then(res => res.data.createDeviceGroup);
			console.log("group",group);
			const requests = this.fieldArray('devices').map((deviceID) =>
                API.graphql({
                    query: updateDevice,
                    variables: {
                        input: {
                            id: deviceID,
               				device_groupID: group.id
                        },
                    },
                }).then((res) => res.data.updateProduct)
            );
            await Promise.all(requests);


			this.$store.commit('ADD_ITEM', { field: 'categories', item: group })
			this.processing=false
			this.$router.push('/device-groups');
			
		},
		//add device control
		// addDeviceOpen(){
		// 	this.addPopupInfo.show=true
		// 	this.addPopupInfo.devices=this.fieldValuesFree('devices')
        // },
        // addDeviceClose(){
		// 	this.addPopupInfo.show=false
		// 	this.addPopupInfo.devices=null
        // },
        // addDeviceSubmit(devices){
		// 	this.addFieldItems('devices',devices)
		// 	this.addDeviceClose()
        // },
	}
};
</script>